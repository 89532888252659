import { Loader } from "components";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AuthService } from "services";
import ApiService from "services/ApiService";
import { useStores } from "stores";

const TwoFactorAuth = () => {
  const [loading, setLoading] = useState(false);
  const RootStore = useStores();

  const handleSubmit = async (event) => {
    setLoading(true);

    event.preventDefault();
    const result = await AuthService.twoFactorAuth(event.target[0].value);

    if (result.status === 204) {
      ApiService.setComplete2fa(true);
      RootStore.setComplete2fa(true);
    } else {
      setLoading(false);
    }
  };

  const handleBackButtonClick = () => {
    RootStore.reset();
  };

  return (
    <div>
      <div className="d-flex align-items-center auth px-0">
        <div className="row w-100 mx-0">
          <div className="col-xl-4 col-md-8 mx-auto">
            <div className="auth-form-light text-left py-5 px-4 px-sm-5">
              <div className="brand-logo">
                <img
                  src={require("../../assets/images/logo-full.png")}
                  alt="logo"
                />
              </div>

              <h4>TheaterMakers Dashboard</h4>

              <h6 className="font-weight-light">
                Voltooi 2FA om door te gaan.
              </h6>

              <h6 className="font-weight-light">
                Gebruik een eenmalige code van uw authenticatieapparaat. Als u
                deze bent kwijtgeraakt, gebruikt u een van uw herstelcodes.
              </h6>

              <Form className="pt-3" onSubmit={handleSubmit}>
                <Form.Group className="d-flex search-field">
                  <Form.Control
                    type="twoFactor"
                    placeholder="012345"
                    size="lg"
                    className="h-auto"
                    required
                  />
                </Form.Group>

                <div className="mt-3">
                  <button className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn position-relative">
                    INVULLEN 2FA
                    <Loader
                      display={loading}
                      position={"right"}
                      style={{ top: "6px", right: "15px" }}
                      size={40}
                    ></Loader>
                  </button>
                </div>
                <div className="my-2 d-flex justify-content-between align-items-center">
                  <Link
                    to="login"
                    onClick={handleBackButtonClick}
                    className="auth-link text-black"
                  >
                    Terug naar login
                  </Link>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(TwoFactorAuth);
