import React from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { Trans } from "react-i18next";
import { RootStore } from "stores";
import { observer } from "mobx-react-lite";
import storageUtil from "util/storageUtil";
import Select from "react-select-v2";

const Sidebar = ({ routes }: { routes: any }) => {
  const location = useLocation();
  const history = useHistory();

  const isPathActive = (path) => {
    return location.pathname.startsWith(path);
  };

  const menuItemsConfig = {
    locations: {
      displayName: "Locaties",
      icon: "mdi-map-marker",
    },
    performances: {
      displayName: "Voorstellingen",
      icon: "mdi-theater",
    },
    rooms: {
      displayName: "Kamers",
      icon: "mdi-home",
    },
    personas: {
      displayName: "Personages",
      icon: "mdi-account",
    },
    employees: {
      displayName: "Medewerkers",
      icon: "mdi-account-tie",
    },
    users: {
      displayName: "Gebruikers",
      icon: "mdi-account-circle",
      separator: true,
    },
    faq: {
      displayName: "FAQ",
      icon: "mdi-help",
    },
    fragments: {
      displayName: "Fragmenten",
      icon: "mdi-music",
    },
    statements: {
      displayName: "Stellingen",
      icon: "mdi-vote",
    },
    posts: {
      displayName: "Berichten",
      icon: "mdi-newspaper",
    },
    helpQuestions: {
      displayName: "Hulpvragen",
      icon: "mdi-account-question",
      separator: true
    },
    helpQuestionAnswers: {
      displayName: "Hulpvraag antwoorden",
      icon: "mdi-reply",
      separator: false
    },
  };

  const eventOptions = [
    {
      label: "Theater",
      options: RootStore.EventStore.TheaterEvents.map((event) => {
        return {
          value: event.id,
          label: event.name,
        };
      }),
    },
    {
      label: "Overig",
      options: RootStore.EventStore.ExternalEvents.map((event) => {
        return {
          value: event.id,
          label: event.name,
        };
      }),
    },
  ];

  const selectedEvent = eventOptions
    .map((x) => x.options)
    .reduce((result, options) => result.concat(options))
    .find(({ value }) => value === RootStore.currentEvent?.id);

  const onEventChanged = (value) => {
    RootStore.setCurrentEvent(value);
    return history.push("/");
  };

  const renderNavItem = (route) => {
    const viewName = route.key;
    var path = route.props.path;

    if (route.props.children && route.props.children.length > 0) {
      path = route.props.children[0].props.path;
    }

    const config = menuItemsConfig[viewName];
    let displayName = viewName;
    let icon = "mdi-arrow-right";
    let separator = false;

    if (config) {
      displayName = config.displayName;
      icon = config.icon;
      separator = config.separator;
    }

    return (
      <React.Fragment key={viewName}>
        {separator && <hr className="w-100" />}
        <li className={isPathActive(path) ? "nav-item active" : "nav-item"}>
          <Link className="nav-link" to={path}>
            <i className={"menu-icon mdi " + icon}></i>
            <span className="menu-title">
              <Trans>{displayName}</Trans>
            </span>
          </Link>
        </li>
      </React.Fragment>
    );
  };

  return (
    <nav className="sidebar sidebar-offcanvas" id="sidebar">
      <div className="text-center sidebar-brand-wrapper d-flex align-items-center">
        <a className="sidebar-brand brand-logo" href="/">
          <img src={require("../assets/images/logo.png")} alt="logo" />
        </a>
        <a className="sidebar-brand brand-logo-mini pt-3" href="/">
          <img src={require("../assets/images/logo.png")} alt="logo" />
        </a>
      </div>
      <ul className="nav">
        <li className="nav-item nav-profile not-navigation-link">
          <div className="nav-link">
            <div className="d-flex justify-content-between align-items-start">
              <Link className="nav-link" to="/wachtwoord-wijzigen">
                <div className="text-center">
                  {RootStore.User.image ? (
                    <img
                      src={storageUtil.getStoragePath(RootStore.User.image)}
                      alt="Logged in user"
                      className="mx-auto img-lg mb-3 rounded-circle"
                    />
                  ) : (
                    <></>
                  )}

                  <p className="profile-name">
                    <Trans>
                      {RootStore.User ? RootStore.User.fullName : ""}
                    </Trans>
                  </p>
                  <small className="designation text-muted text-small">
                    <Trans>{RootStore.User && RootStore.User?.email}</Trans>
                  </small>
                </div>
              </Link>
            </div>
          </div>
        </li>
      </ul>

      <div className="col-md-12 mb-3">
        <p>
          Evenement
          {RootStore.User.IsAdmin && (
            <>
              <Link className="ml-2" to="/evenement-wijzigen">
                <i className={"menu-icon mdi mdi-pencil"}></i>
              </Link>
              <Link className="ml-2" to="/evenement-toevoegen">
                <i className={"menu-icon mdi mdi-plus"}></i>
              </Link>
            </>
          )}
        </p>

        <div className="row">
          <Select
            className="col-lg-12"
            options={eventOptions}
            value={selectedEvent}
            onChange={(selectedEvent) => {
              onEventChanged(selectedEvent.value);
            }}
          ></Select>
        </div>
      </div>

      <ul className="nav">
        {Object.keys(routes).map((key) => renderNavItem(routes[key]))}
      </ul>
    </nav>
  );
};

export default observer(Sidebar);
